import React from 'react';
import {InternalError as InternalErrorPage} from 'benlux-ecommerce-ui';

import {MenuQuery} from '../queries/MenuQuery';

const InternalError = () =>
  <MenuQuery>
    {({menu}) => <InternalErrorPage categories={menu}/>}
  </MenuQuery>

export default InternalError;
