import gql from "graphql-tag";
import { Query } from "../components/Query";

export const GET_PRODUCT = gql`
  query Product($id: String!) {
    product(id: $id) {
      id
      sku
      name
      description
      retailPrice
      ingredients
      usageTips
      shortDescription
      promotionalBadge
      manufacturer
      thumbnail
      attributeSetName
      configurableAttributeCode
      selectedProductChildId
      new
      urlPath
      gwpBlock {
        id
        title
        description
        giftImg
      }
      brand {
        name
        urlKey
        thumbnailUrl
      }
      price {
        regular
        special
        minTier
        suggestedRetail
      }
      tierPrices {
        qty
        value
        discount
      }
      currency
      gallery {
        type
        full
        thumbnail
        embedUrl
      }
      attributes {
        value
        attributeCode
      }
      configurableOptions {
        attributeId
        productId
        values {
          hexColor
          price {
            regular
            special
            minTier
            suggestedRetail
          }
          gallery {
            type
            full
            thumbnail
            embedUrl
          }
          valueIndex
          label
          inStock
          productId
        }
      }
      stock {
        isInStock
        maxQty
      }
      seo {
        title
        description
      }
      breadcrumbs {
        name
        urlPath
      }
      rating {
        average
        reviewsCount
      }
    }
    trustpilotData {
      profileUrl
      ratingCount
      ratingValue
      bestRating
      starsString
      stars
    }
  }
`;

export class ProductQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT
  };
}
