import gql from 'graphql-tag';
import {Query} from '@deity/falcon-ecommerce-uikit';

export const GET_PRODUCT_LINKS = gql`
  query ProductLinks($id: String!) {
    productLinks(id: $id) {
      items {
        id
        sku
        name
        shortDescription
        promotionalBadge
        manufacturer
        thumbnail
        urlPath
        brandName
        price {
          regular
          special
        }
        attributes {
          value
          attributeCode
        }
        configurableOptions {
          values {
            price {
              regular
              special
            }
          }
        }
      }
    }
  }
`;

export class ProductLinksQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT_LINKS,
  };
}
