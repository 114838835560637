import React from 'react';
import PropTypes from 'prop-types';
import { Query as ApolloQuery } from 'react-apollo';
import { NetworkStatus } from 'apollo-client';
import { Loader } from '@deity/falcon-ecommerce-uikit';

import InternalError from './InternalError';

export class Query extends React.Component {
  static propTypes = {
    ...ApolloQuery.propTypes,
    loader: PropTypes.bool
  };

  getErrorCode(error) {
    if (error) {
      const { graphQLErrors } = error;
      if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0) {
        const { extensions = {} } = graphQLErrors[0];
        const { code } = extensions;

        return code;
      }
    }

    return undefined;
  }

  render() {
    const { children, fetchMore, passLoading, ...restProps } = this.props;

    return (
      <ApolloQuery {...restProps}>
        {({ networkStatus, error, data, variables, loading, fetchMore: apolloFetchMore }) => {
          if (error) {
            // const errorCode = this.getErrorCode(error);
            // TODO: add passErrors property or check if errorPolicy === 'all' and pass thru render props all extracted/formated errors with errorcodes instead of inline error message
            return <InternalError/>;
          }

          loading = networkStatus === NetworkStatus.loading || (networkStatus !== NetworkStatus.fetchMore && loading);
          if (!passLoading && loading) {
            return <Loader />;
          }

          return children({
            ...data,
            loading,
            networkStatus,
            fetchMore: fetchMore ? () => fetchMore(data, variables, apolloFetchMore) : undefined
          });
        }}
      </ApolloQuery>
    );
  }
}
