import { useEffect } from "react";
import { trackView } from "../../utils/kiliba";

function KilibaProductViewTracker({ productId }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      trackView("product", productId);
    }
  }, []);

  return null;
}

export { KilibaProductViewTracker };
