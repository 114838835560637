import React, { useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { HeadbandCarousel, ProductSwitchForm } from "benlux-ecommerce-ui";

import { ProductQuery } from "../../queries/ProductQuery";
import { ProductReviewsQuery } from "../../queries/ProductReviewsQuery";
import { Product } from "./components/Product";
import { ReviewForm } from "../../components/ReviewForm";
import { GET_CUSTOMER, Query } from "@deity/falcon-ecommerce-uikit";
import { ProductLinksQuery } from "../../queries/ProductLinksQuery";
import { graphql } from "react-apollo";
import { KilibaProductViewTracker } from "../../components/KilibaTracker/KilibaProductViewTracker";

const productSchema = (product, productReviews, trustpilotData) => {
  const { rating } = product;
  const {
    productReviews: { reviews }
  } = productReviews;

  const data = {
    "@context": "http://schema.org/",
    "@type": "CreativeWorkSeries",
    name: product.name + " - " + product.shortDescription,
    image: product.gallery.map(image => image.full),
    description: product.description,
    /* Note: CreativeWorkSeries not allowing sku and brand properties
    "sku": product.id,
    "brand": {
      "@type": "Brand",
      "name": product.brand.name,
    },
    */
    review: reviews.map(review => {
      return {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: review.rating.toString()
        },
        author: {
          "@type": "Person",
          name: review.nickName
        },
        datePublished: review.createdAt,
        name: review.title,
        reviewBody: review.detail
      };
    }),
    offers:
      product.configurableOptions.length > 0
        ? product.configurableOptions.map(options =>
            options.values.map(value => {
              return {
                "@type": "Offer",
                url: "https://www.benlux.fr" + product.urlPath,
                priceCurrency: "EUR",
                sku: value.productId,
                price: value.price.special
                  ? value.price.special
                  : value.price.regular,
                name:
                  product.name +
                  " - " +
                  product.shortDescription +
                  " - " +
                  value.label,
                itemCondition: "https://schema.org/NewCondition",
                availability: value.inStock
                  ? "https://schema.org/InStock"
                  : "https://schema.org/OutOfStock"
              };
            })
          )
        : {
            "@type": "Offer",
            url: "https://www.benlux.fr" + product.urlPath,
            priceCurrency: "EUR",
            sku: product.id,
            price: product.price.special
              ? product.price.special
              : product.price.regular,
            name: product.name + " - " + product.shortDescription,
            itemCondition: "https://schema.org/NewCondition",
            availability: product.stock.isInStock
              ? "https://schema.org/InStock"
              : "https://schema.org/OutOfStock"
          }
  };

  /* 
  if (rating.reviewsCount > 0) {
    data.aggregateRating = {
      "@type": "AggregateRating",
      "ratingValue": rating.average.toString(),
      "reviewCount": rating.reviewsCount
    }
  }
  */
  data.aggregateRating = {
    "@type": "AggregateRating",
    ratingValue: trustpilotData.ratingValue.toString(),
    reviewCount: trustpilotData.ratingCount
  };

  return JSON.stringify(data);
};

const productFaqSchema = questions => {
  const data = {
    "@context": "http://schema.org/",
    "@type": "FAQPage",
    mainEntity: questions.map(question => {
      return {
        "@type": "Question",
        name: question.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: question.answer
        }
      };
    })
  };

  return JSON.stringify(data);
};

const ProductFAQ = ({ product }) => {
  const faq = [];

  //console.log(product)

  try {
    /* Product with tint */
    if (
      product.configurableAttributeCode &&
      product.configurableAttributeCode.indexOf("tint") > -1
    ) {
      const firstTint = product.configurableOptions[0].values[0];
      const suggestedRetailPrice =
        firstTint.price.suggestedRetail > firstTint.price.regular
          ? firstTint.price.suggestedRetail
          : firstTint.price.regular;
      const currentPrice = firstTint.price.special
        ? firstTint.price.special
        : firstTint.price.regular;
      const discountPercentage = Math.round(
        ((suggestedRetailPrice - currentPrice) / suggestedRetailPrice) * 100
      );

      faq.push({
        question: `Quel est le prix de vente conseillé pour ${product.name} de ${product.brand.name} ?`,
        answer: `Le prix de vente conseillé est de ${suggestedRetailPrice
          .toFixed(2)
          .replace(".", ",")} € (teinte ${
          firstTint.label
        }) et le prix moins cher Benlux est de ${currentPrice
          .toFixed(2)
          .replace(".", ",")} € (teinte ${firstTint.label}).`
      });

      faq.push({
        question: `De combien est la remise sur ${product.name} de ${product.brand.name} sur benlux.fr ?`,
        answer: `Chez Benlux, vous bénéficiez d'une réduction d'environ ${discountPercentage}% sur le prix de vente initial de ${product.name} de ${product.brand.name}.`
      });
    }

    /* Product with volume */
    if (
      product.configurableAttributeCode &&
      product.configurableAttributeCode === "volume"
    ) {
      const firstFormat = product.configurableOptions[0].values[0];
      const suggestedRetailPrice =
        firstFormat.price.suggestedRetail > firstFormat.price.regular
          ? firstFormat.price.suggestedRetail
          : firstFormat.price.regular;
      const currentPrice = firstFormat.price.special
        ? firstFormat.price.special
        : firstFormat.price.regular;
      const discountPercentage = Math.round(
        ((suggestedRetailPrice - currentPrice) / suggestedRetailPrice) * 100
      );

      faq.push({
        question: `Quel est le prix de vente conseillé pour ${product.name} ${product.shortDescription} de ${product.brand.name} ?`,
        answer: `Le prix de vente conseillé est de ${suggestedRetailPrice
          .toFixed(2)
          .replace(".", ",")} € (format ${
          firstFormat.label
        }) et le prix moins cher Benlux est de ${currentPrice
          .toFixed(2)
          .replace(".", ",")} € (format ${firstFormat.label}).`
      });

      faq.push({
        question: `De combien est la remise sur ${product.name} ${product.shortDescription} de ${product.brand.name} sur benlux.fr ?`,
        answer: `Chez Benlux, vous bénéficiez d'une réduction d'environ ${discountPercentage}% sur le prix de vente initial de ${product.name} ${product.shortDescription} de ${product.brand.name}.`
      });

      if (product.attributeSetName === "Fragrance") {
        const availableVolumes = product.configurableOptions[0].values.map(
          ({ label }) => label
        );
        faq.push({
          question: `Quelles contenances sont disponibles chez Benlux pour ${product.name} ${product.shortDescription} de ${product.brand.name} ?`,
          answer: `Sur benlux.fr, vous retrouverez ${product.name} ${
            product.shortDescription
          } de ${product.brand.name} en format : ${availableVolumes.join(
            ", "
          )}.`
        });

        const topNotes = product.attributes.find(
          a => a.attributeCode === "topNotes"
        );
        const heartNotes = product.attributes.find(
          a => a.attributeCode === "heartNotes"
        );
        const bottomNotes = product.attributes.find(
          a => a.attributeCode === "bottomNotes"
        );

        /* faq.push({
          question: `Quelles sont les familles olfactives de ${product.name} ${product.shortDescription} de ${product.brand.name} ?`,
          answer: `${product.name} ${product.shortDescription} de ${product.brand.name} est un parfum Floral Fruité. Les notes de tête sont ${topNotes.value}, les notes de coeur sont ${heartNotes.value} et les notes de fond sont ${bottomNotes.value}.`
        }); */

        if (
          topNotes &&
          topNotes.value.length > 0 &&
          heartNotes &&
          heartNotes.value.length > 0 &&
          bottomNotes &&
          bottomNotes.value.length > 0
        ) {
          faq.push({
            question: `Quelles sont les notes olfactives de ${product.name} ${product.shortDescription} de ${product.brand.name} ?`,
            answer: `${product.name} ${product.shortDescription} de ${product.brand.name} est un parfum dont les notes de tête sont ${topNotes.value}, les notes de coeur sont ${heartNotes.value} et les notes de fond sont ${bottomNotes.value}.`
          });
        }
      }
    }

    if (!product.configurableAttributeCode) {
      const suggestedRetailPrice =
        product.price.suggestedRetail > product.price.regular
          ? product.price.suggestedRetail
          : product.price.regular;
      const currentPrice = product.price.special
        ? product.price.special
        : product.price.regular;
      const discountPercentage = Math.round(
        ((suggestedRetailPrice - currentPrice) / suggestedRetailPrice) * 100
      );

      faq.push({
        question: `Quel est le prix de vente conseillé pour ${product.name} de ${product.brand.name} ?`,
        answer: `Le prix de vente conseillé est de ${suggestedRetailPrice
          .toFixed(2)
          .replace(
            ".",
            ","
          )} € et le prix moins cher Benlux est de ${currentPrice
          .toFixed(2)
          .replace(".", ",")} €.`
      });

      faq.push({
        question: `De combien est la remise sur ${product.name} de ${product.brand.name} sur benlux.fr ?`,
        answer: `Chez Benlux, vous bénéficiez d'une réduction d'environ ${discountPercentage}% sur le prix de vente initial de ${product.name} de ${product.brand.name}.`
      });
    }

    if (product.sku === "lancome-79") {
      product.attributes.push({
        attributeCode: "lipstick_finish",
        value: "shine"
      });
    }

    if (product.sku === "lancome-92") {
      product.attributes.push({
        attributeCode: "makeup_foundation_finish",
        value: "matte"
      });
    }

    const attributesFAQ = [
      {
        attributeCode: "bio",
        attributeType: "boolean",
        questionTemplate: `${product.name} de ${product.brand.name}, est-il est bio ?`,
        answerTemplate: `Oui, ${product.name} ${product.shortDescription} de ${product.brand.name} est certifié bio et en grande partie composé de principes actifs issus de la nature : huiles végétales, huiles essentielles, extraits de plantes...`
      },
      {
        attributeCode: "concentration",
        attributeType: "templated",
        attributeSets: ["fragrance"],
        templates: [
          {
            value: "eau de parfum",
            questionTemplate:
              "Est-ce une eau de parfum ou une eau de toilette ?",
            answerTemplate: `${product.name} de ${product.brand.name} est une eau de parfum. C'est-à-dire que la concentration en parfum est de 15 à 20%, contrairement à une eau de toilette qui en contient environ 5 à 15% et à une eau de cologne qui en possède entre 3 et 6%.`
          },
          {
            value: "eau de toilette",
            questionTemplate:
              "Est-ce une eau de toilette ou une eau de parfum ?",
            answerTemplate: `${product.name} de ${product.brand.name} est une eau de toilette. C'est-à-dire que la concentration en parfum est d'environ 5 à 15%. C'est un peu moins qu'une eau de parfum qui en contient entre 15 et 20%, mais un peu plus qu'une eau de cologne qui est concentrée à environ 5%.`
          },
          {
            value: "eau de cologne",
            questionTemplate:
              "Est-ce une eau de cologne ou une eau de toilette ?",
            answerTemplate: `${product.name} de ${product.brand.name} est une eau de cologne. C'est-à-dire que sa concentration en parfum est d'environ 3 à 6%. C'est donc moins concentré qu'une eau de toilette qui en contient entre 5 et 15%, et une eau de parfum qui possède entre 15 et 20% de parfum pur.`
          }
        ]
      },
      {
        attributeCode: "concentration",
        attributeType: "templated",
        attributeSets: ["default"],
        templates: [
          {
            value: "eau de parfum",
            questionTemplate:
              "Est-ce que le coffret contient une eau de parfum ou une eau de toilette ?",
            answerTemplate: `Le coffret ${product.name} de ${product.brand.name} contient une eau de parfum. Cela signifie que sa concentration en parfum est de 15 à 20%, plus qu'une eau de toilette qui en contient environ 5 à 15% et qu'une eau de cologne qui en possède entre 3 et 6%.`
          },
          {
            value: "eau de toilette",
            questionTemplate:
              "Est-ce que le coffret contient une eau de toilette ou une eau de parfum ?",
            answerTemplate: `Le coffret ${product.name} de ${product.brand.name} contient une eau de toilette. Cela signifie que sa concentration en parfum est d'environ 5 à 15%. C'est moins qu'une eau de parfum qui en contient environ 15 à 20%, mais mais un peu plus qu'une eau de cologne qui est à environ 5%.`
          }
        ]
      },
      {
        attributeCode: "lipstick_finish",
        attributeType: "templated",
        templates: [
          {
            value: "shine",
            questionTemplate: `Quelle est la finition du rouge à lèvres ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `La finition de ${product.name} de ${product.brand.name} est brillante et permet d'ajouter un effet repulpant à vos lèvres, ce qui idéal pour des lèvres fines.`
          }
        ]
      },
      {
        attributeCode: "makeup_foundation_finish",
        attributeType: "templated",
        templates: [
          {
            value: "matte",
            questionTemplate: `Quel est le fini du fond de teint ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} de ${product.brand.name} est idéal pour un fini mat. Il unifie le teint, redonne de l'éclat au visage et camoufle efficacement les brillances de la peau.`
          }
        ]
      },
      {
        attributeCode: "makeupFoundationTexture",
        attributeType: "templated",
        templates: [
          {
            value: "liquide",
            questionTemplate: `Quelle est la texture de ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} de ${product.brand.name} a une texture liquide. Il offre une couvrance uniforme et une finition naturelle. Sa texture légère permet une application facile et une tenue longue durée.`
          }
        ]
      },
      {
        attributeCode: "waterproof",
        attributeType: "boolean",
        questionTemplate: `${product.name} de ${product.brand.name} est-il waterproof ?`,
        answerTemplate: `Oui, il est waterproof, résiste à l'eau et à la transpiration. Il est donc idéal à porter en cas de forte chaleur, à la plage, pour les activités sportives ou bien pour se baigner.`
      },
      {
        attributeCode: "boxContent",
        attributeType: "singleTemplated",
        template: {
          questionTemplate: `Que contient le ${product.shortDescription.toLowerCase()} ${
            product.name
          } de ${product.brand.name} ?`,
          answerTemplate: `Ce ${product.shortDescription.toLowerCase()} ${
            product.name
          } est composé de :<br>`
        }
      },
      {
        attributeCode: "bagType",
        attributeType: "templated",
        templates: [
          {
            value: "cabas",
            questionTemplate: `Quel type de sac est ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est un sac cabas, c'est un must-have pour toute personne à la recherche d'un accessoire pratique et élégant. Avec sa grande capacité de rangement et son design intemporel, ce sac vous permet de transporter toutes vos affaires en toute simplicité !`
          },
          {
            value: "sac seau",
            questionTemplate: `Quel type de sac est ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est un sac seau, c'est l'accessoire parfait pour une touche d'élégance décontractée à votre tenue. Avec leur forme unique et leur ouverture supérieure, ce sac vous permet de ranger facilement vos affaires pour une sortie en ville ou même une journée au travail.`
          },
          {
            value: "sac à bandoulière",
            questionTemplate: `Quel type de sac est ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est un sac à bandoulière, c'est un accessoire pratique et tendance pour vos déplacements. Dotés d'un design épuré et d'une bandoulière réglable, ce sac vous permet de transporter facilement toutes vos affaires sans encombrement.`
          },
          {
            value: "sac ceinture",
            questionTemplate: `Quel type de sac est ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est un sac ceinture, c'est un accessoire à la mode et ultra-pratique. Il se porte à taille et vous permet d'avoir les mains libres tout en ayant avec vous vos objets essentiels : cartes, téléphone, etc.`
          }
        ]
      },
      {
        attributeCode: "bagMaterial",
        attributeType: "templated",
        templates: [
          {
            value: "cuir",
            questionTemplate: `Quelle est la matière de ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est un sac en cuir. Le cuir est un matériau résistant qui peut durer des années avec un bon entretien. Un sac en cuir bien entretenu peut durer des décennies, ce qui en fait un investissement judicieux.`
          },
          {
            value: "toile",
            questionTemplate: `Quelle est la matière de ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est un sac en toile. Les sacs en toile sont fabriqués à partir de matériaux robustes qui leur permettent de résister à l'usure quotidienne. Les toiles utilisées sont généralement de haute qualité, ce qui les rend résistantes aux déchirures et aux abrasions.`
          }
        ]
      },
      {
        attributeCode: "walletMaterial",
        attributeType: "templated",
        templates: [
          {
            value: "cuir",
            questionTemplate: `Quelle est la matière de ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est en cuir. En plus de son aspect esthétique, le portefeuille en cuir a de nombreux avantages. Il est résistant et durable, et le cuir de qualité supérieure peut durer des années avec un entretien adéquat.`
          },
          {
            value: "toile",
            questionTemplate: `Quelle est la matière de ${product.name} de ${product.brand.name} ?`,
            answerTemplate: `${product.name} est en toile. Fabriqué à partir de matériaux résistant à l’eau et à la déchirure, le portefeuille en toile est une option esthétique, unique, pratique et abordable pour tous ceux qui cherchent à protéger leurs effets personnels en toute sécurité.`
          }
        ]
      }
    ];

    attributesFAQ.forEach(attribute => {
      if (
        !attribute.attributeSets ||
        attribute.attributeSets.includes(product.attributeSetName.toLowerCase())
      ) {
        if (attribute.attributeType === "boolean") {
          if (
            product.attributes.find(
              a =>
                attribute.attributeCode === a.attributeCode && a.value === "Yes"
            )
          ) {
            faq.push({
              question: attribute.questionTemplate,
              answer: attribute.answerTemplate
            });
          }
        } else if (attribute.attributeType === "templated") {
          if (
            product.attributes.find(
              a =>
                attribute.attributeCode === a.attributeCode &&
                attribute.templates.find(t => t.value === a.value.toLowerCase())
            )
          ) {
            const productAttribute = product.attributes.find(
              a => attribute.attributeCode === a.attributeCode
            );
            const faqTemplate = attribute.templates.find(
              t => t.value === productAttribute.value.toLowerCase()
            );
            faq.push({
              question: faqTemplate.questionTemplate,
              answer: faqTemplate.answerTemplate
            });
          }
        } else if (attribute.attributeType === "singleTemplated") {
          if (
            product.attributes.find(
              a => attribute.attributeCode === a.attributeCode && a.value
            )
          ) {
            faq.push({
              question: attribute.template.questionTemplate,
              answer:
                attribute.template.answerTemplate +
                `${product.attributes
                  .find(a => a.attributeCode === "boxContent")
                  .value.trim()
                  .replace(/(?:\r\n|\r|\n)/g, "<br>")}.`
            });
          }
        }
      }
    });
  } catch (e) {
    console.error(e);
  }

  if (faq.length > 0) {
    return (
      <>
        <Helmet>
          <script type="application/ld+json">{productFaqSchema(faq)}</script>
        </Helmet>
        <div
          style={{
            fontSize: "1rem",
            maxWidth: "1200px",
            margin: "auto",
            padding: "48px 12px 0"
          }}
        >
          {faq.map((question, index) => (
            <ProductFAQQuestion question={question} key={index} />
          ))}
        </div>
      </>
    );
  }

  return null;
};

const ProductFAQQuestion = ({ question, initialOpen = false }) => {
  const [open, setOpen] = useState(initialOpen);

  return (
    <div style={{ marginBottom: "12px", background: "#fafafa" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          padding: "12px",
          userSelect: "none"
        }}
        onClick={() => setOpen(!open)}
      >
        <h2
          style={{
            margin: 0,
            fontSize: ".95rem",
            marginBottom: 0,
            background: "#fafafa",
            fontWeight: 600,
            flex: 1
          }}
        >
          {question.question}
        </h2>
        <b style={{ marginLeft: "24px" }}>{open ? "-" : "+"}</b>
      </div>
      <p
        style={{
          display: open ? "block" : "none",
          fontSize: ".95rem",
          margin: 0,
          padding: "0 12px 12px 12px"
        }}
        dangerouslySetInnerHTML={{ __html: question.answer }}
      />
    </div>
  );
};

const ProductPage = ({ id, location, data, history }) => (
  <ProductQuery variables={{ id, path: location.pathname }}>
    {({ product, trustpilotData }) => {
      if (typeof window !== "undefined") {
        try {
          console.log("ID", product.id);
          console.log("SKU", product.sku);
          console.log("BRAND", product.brand.name);
          console.log("NAME", product.name + " " + product.shortDescription);
          console.log("URL", product.urlPath);
        } catch (e) {}
      }

      const { seo, name, shortDescription, brand, gallery, urlPath } = product;
      const title =
        seo.title ||
        name + " " + shortDescription + " " + brand.name + " Pas Cher";
      const mainImage = gallery.length > 0 ? gallery[0].full : "";
      const description = seo.description || product.description || "";
      const isSignedIn = !!data.customer;

      return (
        <>
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* TODO: dynamic base path */}
            <link rel="canonical" href={"https://www.benlux.fr" + urlPath} />
            <meta property="og:type" content="og:product" />
            <meta property="og:title" content={title + " ‣ BENLUX"} />
            <meta property="og:image" content={mainImage} />
            <meta property="og:image:width" content="846" />
            <meta property="og:image:height" content="1218" />
            <meta property="og:description" content={description} />
            <meta
              property="og:url"
              content={"https://www.benlux.fr" + urlPath}
            />
            <meta
              property="product:price:amount"
              content={product.price.regular}
            />
            <meta property="product:price:currency" content="EUR" />
            <meta name="twitter:card" content="summary" />
            <meta property="twitter:domain" content="benlux.fr" />
            <meta
              property="twitter:url"
              content={"https://www.benlux.fr" + urlPath}
            />
            <meta name="twitter:title" content={title + " ‣ BENLUX"} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={mainImage} />
            <meta name="page-loaded" content="true" />
          </Helmet>
          <KilibaProductViewTracker productId={product.id} />
          <Product product={product} trustpilotData={trustpilotData} />
          <ProductReviewsQuery variables={{ id: product.id }}>
            {productReviews => {
              return (
                <>
                  <Helmet>
                    <script type="application/ld+json">
                      {productSchema(product, productReviews, trustpilotData)}
                    </script>
                  </Helmet>
                  <ReviewForm>
                    {({
                      addReviewMutation: {
                        result: { loading, error }
                      },
                      formik: { setFieldValue }
                    }) => (
                      <ProductSwitchForm
                        product={product}
                        setFieldValueOpinion={(field, value) => {
                          setFieldValue(field, value);
                        }}
                        productReviews={productReviews}
                        isSignedIn={isSignedIn}
                        onRedirectToSignIn={() =>
                          history.push("/connexion?next=" + urlPath)
                        }
                      />
                    )}
                  </ReviewForm>
                </>
              );
            }}
          </ProductReviewsQuery>
          <ProductLinksQuery variables={{ id: product.sku }}>
            {({ productLinks }) => {
              if (productLinks.items && productLinks.items.length > 0) {
                return (
                  <HeadbandCarousel
                    showButton={false}
                    itemCarousel={productLinks.items}
                    title={"Vous aimerez aussi"}
                    titleTag={"div"}
                    imgLazyLoading={true}
                  />
                );
              }

              return null;
            }}
          </ProductLinksQuery>
          <ProductFAQ product={product} />
        </>
      );
    }}
  </ProductQuery>
);

ProductPage.propTypes = {
  id: PropTypes.string.isRequired
};

export default graphql(GET_CUSTOMER)(ProductPage);
