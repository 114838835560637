import React from 'react';
import {Formik, Form} from 'formik';
import {adopt} from 'react-adopt';
import gql from "graphql-tag";
import {Mutation} from "react-apollo";

export const ADD_REVIEW = gql`
  mutation AddReview($input: AddReviewInput!) {
    addReview(input: $input)
  }
`;

export class AddReviewMutation extends Mutation {
  static defaultProps = {
    mutation: ADD_REVIEW,
  };
}

export function createValidator(product, t) {
  return (values) => {
    const errors = {};

    // handle qty
    if (parseInt(values.qty, 10) < 1) {
      errors.qty = t('product.error.quantity');
    }

    // handle configuration options
    if (product.configurableOptions && product.configurableOptions.length) {
      if (!values.configurableOptions || values.configurableOptions.length !== product.configurableOptions.length) {
        errors.configurableOptions = t('product.error.configurableOptions');
      }
    }

    // todo: handle bundled products
    return errors;
  };
}

/**
 * Combine render props functions into one with react-adopt
 */
export const ReviewForm = adopt({
  // mutation provides addReview method which should be called with proper data
  addReviewMutation: ({render}) => (
    <AddReviewMutation
      onCompleted={() => console.log('AddReviewMutation completed')}>
      {(addReview, result) => render({addReview, result})}
    </AddReviewMutation>
  ),
  // formik handles form operations and triggers submit when onSubmit event is fired on the form
  formik: ({validate, addReviewMutation, render}) => (
    <Formik
      validate={validate}
      onSubmit={(values) => {
        addReviewMutation.addReview({
          variables: {
            input: {
              ...values,
            }
          }
        })
      }}
    >
      {(...props) => <Form>{render(...props)}</Form>}
    </Formik>
  ),
});

