import gql from 'graphql-tag';
import {Query} from '@deity/falcon-ecommerce-uikit';

export const GET_PRODUCT_REVIEWS = gql`
  query ProductReviews($id: String!) {
    productReviews(id: $id) {
      starsCounts {
        count
        value
      }
      reviews {
        title
        detail
        nickName
        rating
        createdAt
      }
    }
  }
`;

export class ProductReviewsQuery extends Query {
  static defaultProps = {
    query: GET_PRODUCT_REVIEWS,
  };
}
