import React, { useState } from "react";
import { Form, Formik } from "formik";
import { FormField } from "../pages/account/SignIn/FormField";
import { Button, ButtonTypes } from "benlux-ecommerce-ui";

export const BackInStockForm = ({ product }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={(values, { resetForm }) => {
        setIsLoading(true);
        let variant = "";
        let productId = product.id;
        if (product.configurableOptions.length > 0) {
          const { configurableOptions } = product;
          const attributeId = configurableOptions[0].attributeId;
          const selectedValueIndex = document.querySelector(
            '[name="' + attributeId + '"]:checked'
          ).value;
          const selectedOption = configurableOptions[0].values.find(
            option => option.valueIndex === selectedValueIndex
          );
          productId = selectedOption.productId;
          variant = selectedOption.label;
        }

        const payload = {
          email: values.email.trim().toLowerCase(),
          productId: parseInt(productId)
        };

        fetch("https://pa.benlux.sntive.net/api/product-alert", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(payload)
        })
          .then(() => {
            resetForm();
            setIsSuccess(true);
            setTimeout(() => {
              setIsSuccess(false);
            }, 5000);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    >
      {({ submitForm }) => (
        <Form>
          <FormField
            name="email"
            type="email"
            required
            placeholder={"Adresse e-mail"}
          />
          <Button
            type={ButtonTypes.Secondary}
            onClick={submitForm}
            as={"button"}
            text={isSuccess ? "C'est enregistré !" : "Recevoir une alerte"}
            loading={isLoading}
          />
        </Form>
      )}
    </Formik>
  );
};
